import { z } from 'zod';

const MAX_FILESIZE_MB = parseInt(
  import.meta.env.VITE_UPLOAD_MAX_FILESIZE || '100',
  10
);
const MAX_FILESIZE_BYTES = MAX_FILESIZE_MB * 1_048_576;

export const uploadMultipleFileSchema = z
  .object({
    files: z.array(
      z.object({
        file: z.instanceof(File),
        description: z
          .string()
          .trim()
          .max(40, 'Description must be 40 characters max'),
        highlighted: z.boolean(),
      })
    ),
  })
  .refine((data) => data.files.length > 0, {
    path: ['files'],
    message: 'You must upload at least one file.',
  })
  .refine(
    (data) => {
      const totalSize = data.files.reduce((sum, f) => sum + f.file.size, 0);
      return totalSize <= MAX_FILESIZE_BYTES;
    },
    {
      path: ['files'],
      message: `The total file size must not exceed ${MAX_FILESIZE_MB} MB.`,
    }
  );

export type UploadFileSchema = z.infer<typeof uploadMultipleFileSchema>;
