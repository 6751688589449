import { MdCheck, MdDelete, MdDeleteOutline } from 'react-icons/md';
import styled from 'styled-components';

import {
  BaseCheckbox,
  FileIcon,
  FormBase,
  type IFormInputTextProps,
} from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

export const FileDropzoneContainer = styled.div`
  margin-bottom: 20px;
`;

export const HighlightText = styled.p`
  font-size: 0.875rem;
  color: ${colors.text};
  margin-bottom: 10px;d
`;

export const FilesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const FileContainer = styled.li`
  align-items: center;
  background-color: ${colors.backgroundHover};
  border-radius: ${radii.lg};
  color: ${colors.text};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  transition: border 0.24s ease-in-out;

  & > span {
    display: contents;
  }
`;

export const FileIconCustom = styled(FileIcon)`
  width: 40px;
  height: 40px;
`;

export const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 1.25rem;
`;

export const FileName = styled.p`
  font-size: 0.75rem;
`;

export const InputText = styled(FormBase.InputText)<IFormInputTextProps>`
  -webkit-appearance: none;
  width: 100%;
  height: 2.35rem;
  border: 1px solid ${colors.gray100};
  border-radius: ${radii.px};
  color: ${colors.text};
  font-size: 1rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
  background-color: ${colors.white300};
`;

export const FileSize = styled.p`
  font-size: 0.75rem;
  color: ${colors.textLight};
`;

export const FileAcceptedIcon = styled(MdCheck)`
  color: ${colors.text};
  width: 1rem;
  height: 1rem;
`;

export const ErrorMessage = styled.p`
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 0.7rem;
  color: ${colors.red300};
`;

export const ActionButton = styled.button`
  all: unset;
  align-items: center;
  border-radius: ${radii.full};
  color: ${colors.blue400};
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  height: 2.5rem;
  justify-content: center;
  margin: 0.25rem;
  width: 2.5rem;

  &:hover {
    background-color: ${colors.white300};
    filter: brightness(0.9);
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const DeleteIconLibrary = styled(MdDelete)`
  width: 1rem;
  height: 1rem;
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  height: 1.75rem;
  width: 1.75rem;
`;

export const Checkbox = styled(BaseCheckbox)`
  margin: 0;
  color: ${colors.blue400};
`;
